<template>
  <div class="barcode-scanner">
    <h1>条形码扫描器 \(^o^)/~</h1>
    <div id="interactive" class="viewport"></div>
    <div class="controls">
      <button @click="startScanner" :disabled="isScanning">开始扫描</button>
      <button @click="stopScanner" :disabled="!isScanning">停止扫描</button>
    </div>
    <div v-if="scannedResult" class="result">
      <h2>扫描结果:</h2>
      <p>{{ scannedResult }}</p>
    </div>
  </div>
</template>

<script>
import Quagga from 'quagga';

export default {
  name: 'BarcodeScannerDemo',
  data() {
    return {
      isScanning: false,
      scannedResult: '',
    };
  },
  methods: {
    startScanner() {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: document.querySelector('#interactive'),
            constraints: {
              facingMode: 'environment', // 使用后置摄像头
            },
          },
          decoder: {
            readers: ['ean_reader', 'ean_8_reader', 'code_128_reader', 'code_39_reader', 'code_39_vin_reader'],
          },
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          this.isScanning = true;
          Quagga.start();
        }
      );

      Quagga.onDetected(this.onBarcodeDetected);
    },
    stopScanner() {
      Quagga.stop();
      this.isScanning = false;
    },
    onBarcodeDetected(result) {
      this.scannedResult = result.codeResult.code;
      this.stopScanner();
    },
  },
  beforeDestroy() {
    if (this.isScanning) {
      this.stopScanner();
    }
  },
};
</script>

<style scoped>
.barcode-scanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.viewport {
  width: 100%;
  max-width: 640px;
  height: 480px;
  margin-bottom: 20px;
  border: 2px solid #333;
  overflow: hidden;
}

.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.result {
  text-align: center;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  font-weight: bold;
}
</style>